<template>
  <CategoryListWrapper>
    <sdCards headless bodypadding="5px">
      <perfect-scrollbar
        ref="scrollbarRef"
        class="always-active-scrollbar"
        :options="{wheelSpeed: 1, swipeEasing: true, suppressScrollX: true}"
      >
        <ul>
          <li v-for="type in categories" :key="type">
            <a :class="`primary ${currentActive === type ? 'active' : ''}`" @click="handleChange(type)">
              {{$t(`models.priceCategory.attributes.categoryTypes.${type}`)}}
            </a>
          </li>
        </ul>
      </perfect-scrollbar>
    </sdCards>
  </CategoryListWrapper>
</template>

<script>
import {
  defineComponent, nextTick, ref, toRefs, watch
} from 'vue';
import VueTypes from 'vue-types';
import _ from 'lodash';
import {CategoryListWrapper} from './style';
import {PerfectScrollbar} from 'vue3-perfect-scrollbar';
import 'vue3-perfect-scrollbar/dist/vue3-perfect-scrollbar.css';

export default defineComponent({
  name: 'PriceCategoriesNavigation',
  components: {CategoryListWrapper, PerfectScrollbar},
  props: {
    categories: VueTypes.array
  },
  emits: ['currentCategory'],
  setup(props, context) {
    const currentActive = ref('');
    const handleChange = (type) => {
      currentActive.value = type;
      context.emit('currentCategory', type);
    };

    const {categories} = toRefs(props);
    const scrollbarRef = ref();
    watch(categories, async (value) => {
      if (!_.isEmpty(value)) {
        handleChange(value[0]);
        await nextTick();
        // Fix scrollbar not showing unless user actually scrolls
        setTimeout(() => scrollbarRef.value.update(), 1);
      }
    }, {deep: true}, {immediate: true});

    return {
      currentActive,
      scrollbarRef,
      handleChange
    };
  }
});
</script>

<style scoped>
.ps {
  height: 70vh;
}
@media only screen and (max-width: 500px){
  .ps {
    height: 10vh;
  }
}
</style>
