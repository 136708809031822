<template>
  <div>
    <app-header :routes="routes" />
    <Main>
      <a-row :gutter="15">
        <a-col :xs="24" :sm="24" :md="6" :lg="5">
          <price-categories-navigation
            :categories="categoryTypes"
            @currentCategory="changeCategory"
          />
        </a-col>
        <a-col :xs="24" :sm="24" :md="18" :lg="19">
          <a-row>
            <a-col :xs="24" :sm="10" :md="8" :lg="8" :xl="8" :xxl="5">
              <filter-bar
                :options="filterBarOptions"
                i18nPath="models.priceCategory.attributes.status"
                @update:filterValue="changeFilter"
              />
            </a-col>
            <a-col :xs="24">
              <category-items-table-form
                v-if="currentCategory !== 'hs_code_list'"
                :key="currentCategory"
                :category="currentCategory"
                :editMode="itemsStatus === 'active'"
              />
              <hs-code-items-table-form
                v-else
                key="hsCodeForm"
                :category="currentCategory"
                :editMode="itemsStatus === 'active'"
              />
            </a-col>
          </a-row>
        </a-col>
      </a-row>
    </Main>
  </div>
</template>

<script>
import {
  computed, defineComponent, ref
} from 'vue';
import {useStore} from 'vuex';
import {Main} from '@/components/shared/styledComponents/Main';
import _ from 'lodash';
import PriceCategoriesNavigation from '@/components/priceLists/PriceCategoriesNavigation';
import CategoryItemsTableForm from '@/components/priceLists/CategoryItemsTableForm';
import HsCodeItemsTableForm from '@/components/priceLists/HSCodeItemsTableForm';
import FilterBar from '@/components/tables/FilterBar';

export default defineComponent({
  name: 'PriceCategories',
  components: {
    Main,
    PriceCategoriesNavigation,
    CategoryItemsTableForm,
    HsCodeItemsTableForm,
    FilterBar
  },
  setup() {
    const routes = [{
      name: 'BackOfficePriceCategories',
      breadcrumbName: 'views.priceCategories.title'
    }];

    const {dispatch, getters} = useStore();
    const categoryTypes = computed(() => getters.categoryTypes);

    dispatch('toggleLoading', true);
    dispatch('getCategoryTypes').then(() => dispatch('toggleLoading', false));

    const currentCategory = ref('');
    const changeCategory = async (categoryType) => {
      currentCategory.value = categoryType;
      getItems();
    };

    const getItems = async () => {
      dispatch('toggleLoading', true);
      await dispatch('getCategoryItems', {
        [_.snakeCase('categoryType')]: currentCategory.value,
        status: itemsStatus.value
      });
      dispatch('toggleLoading', false);
    };

    const filterBarOptions = ['active', 'archived'];
    const itemsStatus = ref('active');
    const changeFilter = (status) => {
      if (itemsStatus.value === status) return;
      itemsStatus.value = status;
      getItems();
    };

    return {
      routes,
      categoryTypes,
      currentCategory,
      filterBarOptions,
      itemsStatus,
      changeCategory,
      changeFilter
    };
  }
});
</script>
