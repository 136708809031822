import Styled from 'vue3-styled-components';

const CategoryListWrapper = Styled.div`
  ul{
    li{
      a{
        display: inline-block;
        word-break: break-word;
        font-weight: 500;
        position: relative;
        padding: ${({theme}) => (!theme.rtl ? '12px 0 12px 20px' : '12px 20px 12px 0')};
        transition: all .3s ease;
        color: ${({theme}) => theme['gray-color']};
        background-color: #fff;
        &.active{
          padding-left: 28px;
          color: ${({theme}) => theme['primary-color']}
          &:before{
            opacity: 1;
            visibility: visible;
            ${({theme}) => (!theme.rtl ? 'left' : 'right')}: -15px;
          }
        }
      }
    }
  }
`;

export {
  CategoryListWrapper
};
