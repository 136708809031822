<template>
  <sdCards headless>
    <FormValidationWrap>
      <VerticalFormStyleWrap>
        <table-style-wrapper>
          <table-wrapper class="table-responsive">
            <a-form
              ref="formRef"
              name="sDash_validation-form"
              layout="vertical"
              :model="items"
              :validateOnRuleChange="false"
            >
              <a-table
                :columns="columns"
                :data-source="items"
                :pagination="false"
                :rowKey="(record) => $_.indexOf(items, record)"
              >
                <template #itemName="{ record }">
                  <div v-if="editMode" class="editable-cell field-col">
                    <div class="editable-cell-input-wrapper">
                      <text-field
                        :rules="[required]"
                        :field-value="items[$_.indexOf(items, record)]['itemName']"
                        @update:fieldValue="inputChanged('itemName', $_.indexOf(items, record), $event)"
                        :fieldKey="[$_.indexOf(items, record), 'itemName']"
                        @pressEnter="pressEnter($event)"
                      />
                    </div>
                  </div>
                  <template v-else class="editable-cell field-col">
                    {{items[$_.indexOf(items, record)]['itemName']}}
                  </template>
                </template>

                <template #mandatory="{ record }">
                  <div :class="`editable-cell field-col ${!editMode ? 'disabled-switch' : ''}`">
                    <div class="editable-cell-input-wrapper">
                      <switch-field
                        :disabled="!editMode"
                        :field-value="items[$_.indexOf(items, record)]['mandatory']"
                        @update:fieldValue="inputChanged('mandatory', $_.indexOf(items, record), $event)"
                        :fieldKey="[$_.indexOf(items, record), 'mandatory']"
                      />
                    </div>
                  </div>
                </template>

                <template #actions="{ record }">
                    <template v-if="editMode && items.length > 1">
                      <remove-item-button
                        :record="record"
                        _classes="single-table-action"
                        @removeItem="handleRemove(record)"
                      />
                    </template>
                  </template>
                </a-table>

              <div v-if="editMode" class="sDash_form-action">
                <span class="float-button-right display-inline">
                  <sdButton class="btn-signin mr-10" type="light"
                    @click="handleAdd" html-type="button">
                      {{$t('actions.add')}}
                  </sdButton>
                  <a-button
                    class="btn-signin mr-10"
                    size="small"
                    type="primary"
                    :loading="loading"
                    :disabled="loading"
                    @click="submitForm"
                  >
                    <span>{{$t('actions.save')}}</span>
                  </a-button>
                </span>
              </div>
            </a-form>
          </table-wrapper>
        </table-style-wrapper>
      </VerticalFormStyleWrap>
    </FormValidationWrap>
  </sdCards>
</template>

<script>
import {
  computed, defineComponent, ref, watch
} from 'vue';
import _ from 'lodash';
import {useI18n} from 'vue-i18n';
import {useStore} from 'vuex';
import VueTypes from 'vue-types';
import {TableStyleWrapper} from '@/components/tables/style.js';
import {TableWrapper} from '@/components/shared/styledComponents/Main';
import {VerticalFormStyleWrap, FormValidationWrap} from '@/components/shared/form/Style';
import TextField from '@/components/shared/fields/Text';
import SwitchField from '@/components/shared/fields/Switch';
import RemoveItemButton from '@/components/registration/RemoveItemButton';
import {required} from '@/helpers/validationRules';
import {inlineTableErrors} from '@/helpers/inlineServerErrorsInTable';
import {scrollToFirstErrorAlternative} from '@/helpers/scrollToFirstErrorInForm';

export default defineComponent({
  name: 'CategoryItemsTableForm',
  components: {
    TableStyleWrapper,
    TableWrapper,
    VerticalFormStyleWrap,
    FormValidationWrap,
    TextField,
    SwitchField,
    RemoveItemButton
  },
  props: {
    category: VueTypes.string,
    editMode: VueTypes.bool
  },
  setup(props) {
    const {t} = useI18n();
    const {getters, dispatch} = useStore();

    const items = ref([]);

    const recordIndex = (record) => _.indexOf(items.value, record);

    const handleAdd = (e, item) => {
      e?.preventDefault();
      items.value.push({
        itemName: item ? item.itemName : '',
        mandatory: item ? item.mandatory : false,
        id: item ? item._jv.id : undefined
      });
    };

    const handleRemove = (item) => {
      const index = recordIndex(item);
      if (index > -1) items.value.splice(index, 1);
    };

    const inputChanged = (key, index, value) => items.value[index][key] = value;

    const pressEnter = (event) => {
      const inputField = document.getElementById(event.id);
      inputField.blur();
    };
    const columns = computed(() => [
      {
        title: t('models.priceCategory.attributes.itemName'),
        dataIndex: 'itemName',
        slots: {customRender: 'itemName'},
        width: '30%'
      },
      {
        title: t('models.priceCategory.attributes.mandatory'),
        dataIndex: 'mandatory',
        slots: {customRender: 'mandatory'}
      },
      {
        slots: {customRender: 'actions'}
      }
    ]);

    const formRef = ref();
    const loading = ref(false);
    const submitForm = (e) => {
      e.preventDefault();
      formRef.value
        .validate()
        .then(async () => {
          loading.value = true;
          const data = _.map(items.value, (item) => {
            const formattedItem = {};
            _.forEach(item, (value, key) => {
              if (value || typeof value === 'boolean') formattedItem[_.snakeCase(key)] = value;
            });
            return formattedItem;
          });
          const errors = await dispatch('updateCategory', {[_.snakeCase('itemsAttributes')]: data, [_.snakeCase('categoryType')]: props.category});
          if (errors) {
            inlineTableErrors(errors[0], formRef);
          }
          loading.value = false;
        }).catch(({errorFields}) => {
          scrollToFirstErrorAlternative(formRef, errorFields, document, window);
        });
    };

    const categoryItems = computed(() => getters.categoryItems);
    watch(categoryItems, (value) => {
      items.value = [];
      if (!_.isEmpty(value)) {
        _.forEach(value, (item) => handleAdd(undefined, item));
      } else if (props.editMode) {
        handleAdd();
      }
    }, {deep: true}, {immediate: true});

    return {
      formRef,
      required,
      columns,
      items,
      loading,
      handleAdd,
      handleRemove,
      submitForm,
      inputChanged,
      categoryItems,
      pressEnter
    };
  }
});
</script>

<style lang="scss">
.editable-cell-input-wrapper .ant-form-item-label {
  height: 0px !important;
}
.editable-cell-input-wrapper .disabled-switch .switch-field-item {
  margin-bottom: 0px;
}
.editable-cell-input-wrapper .ant-switch-disabled {
    opacity: 1 !important;
}
</style>
