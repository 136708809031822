<template>
  <div>
    <div class="table-search">
      <a-row :gutter="[10,0]" type="flex">
        <a-col :xs="24" :sm="12" :md="9" :lg="5" :xl="5" :xxl="4">
            <a-input-search v-model:value="searchValue" :placeholder="$t('views.priceCategories.searchHSCodes')" />
        </a-col>
      </a-row>
    </div>
    <FormValidationWrap>
      <VerticalFormStyleWrap>
        <table-style-wrapper>
          <table-wrapper class="table-responsive">
            <a-form
              ref="formRef"
              name="sDash_validation-form"
              layout="vertical"
              :model="items"
              :validateOnRuleChange="false"
            >
              <a-table
                class="pb-30"
                :data-source="visibleItems"
                :pagination="false"
                :rowKey="(record) => $_.indexOf(items, record)"
              >
                <template #title><h3 class="float-right mb-0 pb-30">{{$t('messages.help.usd')}}</h3></template>
                <a-table-column v-for="(column) in columns" :key="column">
                    <template #title >
                      <span>{{$t(`models.priceCategory.attributes.${column}`)}}</span>
                    </template>

                    <template #default="{record}">
                      <number-field
                        v-if="editMode && column === 'hs_code'"
                        class="editable-table-cell hide-number-handler"
                        :rules="[range]"
                        size="small"
                        :field-value="items[$_.indexOf(items, record)][column]"
                        @update:fieldValue="inputChanged(column, $_.indexOf(items, record), $event)"
                        :fieldKey="[$_.indexOf(items, record), column]"
                      />
                      <template v-else-if="column === 'hs_code'">
                        {{items[$_.indexOf(items, record)][column] || 0}}
                      </template>
                    </template>
                  </a-table-column>

                  <a-table-column key="actions" >
                    <template #title></template>
                    <template #default="{record}">
                      <remove-item-button
                        v-if="editMode && items.length > 0 "
                        :record="record"
                        _classes="single-table-action"
                        @removeItem="handleRemove(record)"
                      />
                    </template>
                  </a-table-column>
              </a-table>

              <div v-if="editMode" class="sDash_form-action">
                <hs-codes-upload-button @success="handleUploadHsCodes" />

                <span class="float-button-right display-inline">
                  <sdButton class="btn-signin mr-10" type="light"
                    @click="handleAdd" html-type="button">
                      {{$t('actions.add')}}
                  </sdButton>

                  <a-button
                    class="btn-signin mr-10"
                    size="small"
                    type="primary"
                    :loading="loading"
                    :disabled="loading"
                    @click="submitForm"
                  >
                    <span>{{$t('actions.save')}}</span>
                  </a-button>
                </span>
              </div>
            </a-form>
          </table-wrapper>
        </table-style-wrapper>
      </VerticalFormStyleWrap>
    </FormValidationWrap>
  </div>
</template>

<script>
import {
  computed, defineComponent, ref, watch
} from 'vue';
import {TableStyleWrapper} from '@/components/tables/style';
import {TableWrapper} from '@/components/shared/styledComponents/Main';
import {VerticalFormStyleWrap, FormValidationWrap} from '@/components/shared/form/Style';
import {rangeNumerical} from '@/helpers/validationRules';
import _ from 'lodash';
import {useStore} from 'vuex';
import VueTypes from 'vue-types';
import NumberField from '@/components/shared/fields/Number';
import RemoveItemButton from '@/components/registration/RemoveItemButton';
import HsCodesUploadButton from './HsCodesUploadButton';
import {scrollToFirstErrorAlternative} from '@/helpers/scrollToFirstErrorInForm';

export default defineComponent({
  name: 'HSCodeItemsTableForm',
  components: {
    TableStyleWrapper,
    TableWrapper,
    VerticalFormStyleWrap,
    FormValidationWrap,
    NumberField,
    RemoveItemButton,
    HsCodesUploadButton
  },
  props: {
    category: VueTypes.string,
    editMode: VueTypes.bool
  },
  setup(props) {
    const {dispatch, getters} = useStore();
    const categoryItems = computed(() => getters.categoryItems);

    watch(categoryItems, (value) => {
      if (!_.isEmpty(value) && value[0].categoryType === props.category) {
        items.value = [];
        _.forEach(value, (item) => handleAdd(undefined, item));
      }
    }, {immediate: true, deep: true});

    const items = ref([]);
    const formRef = ref();

    const handleAdd = (e, item) => {
      e?.preventDefault();
      const formatItem = {};
      formatItem[_.snakeCase('hsCode')] = !_.isEmpty(item) ? item[_.camelCase('hsCode')] : 0;
      formatItem['id'] = item?._jv ? item._jv.id : '';
      items.value.push(formatItem);
    };

    const handleRemove = (item) => {
      const index = _.indexOf(items.value, item);
      if (index < 0) return;

      items.value.splice(index, 1);
      formRef.value.fields[index]?.clearValidate();
    };

    const inputChanged = (key, index, value) => items.value[index][key] = value;

    const loading = ref(false);
    const submitForm = (e) => {
      e.preventDefault();
      formRef.value
        .validate()
        .then(async () => {
          loading.value = true;
          const data = _.map(items.value, (item) => {
            const formattedItem = {};
            _.forEach(item, (value, key) => {
              if (key === 'id' || key === 'hs_code') formattedItem[_.snakeCase(key)] = value || '';
            });
            return formattedItem;
          });
          const errors = await dispatch('updateCategory', {
            [_.snakeCase('itemsAttributes')]: data,
            [_.snakeCase('categoryType')]: props.category
          });
          loading.value = false;
          if (errors) handleErrors(...errors);
        }).catch(({errorFields}) => {
          scrollToFirstErrorAlternative(formRef, errorFields, document, window);
        });
    };

    const columns = ['hs_code'];

    const handleUploadHsCodes = (codes) => {
      _.forEach(codes, (code) => {
        if (!_.find(items.value, (item) => item[_.snakeCase('hsCode')] === code)) {
          handleAdd(undefined, {hsCode: code});
        }
      });
      setTimeout(async () => {
        await formRef.value.validate();
      }, 1);
    };

    const handleErrors = (errors) => {
      _.forOwn(errors, (value, key) => {
        formRef.value.fields[key].validateMessage = value.toString();
        formRef.value.fields[key].validateState = 'error';
      });
    };

    const searchValue = ref('');
    const visibleItems = computed(() => {
      searchValue.value; items.value;
      return _.filter(items.value, (item) => item.hs_code?.toString().includes(searchValue.value) || !item.hs_code);
    });

    return {
      formRef,
      range: rangeNumerical(6, 14),
      items,
      visibleItems,
      loading,
      searchValue,
      handleAdd,
      handleRemove,
      submitForm,
      inputChanged,
      columns,
      handleUploadHsCodes
    };
  }
});
</script>

<style lang="scss" scoped>
.editable-table-cell {
  max-width: 200px !important;
}

.editable-table-cell > .ant-input-number-handler-wrap {
  display: none !important;
}
</style>
