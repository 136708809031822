<template>
  <a-upload
    v-model:file-list="fileList"
    method="PUT"
    accept="application/vnd.openxmlformats-officedocument.spreadsheetml.sheet, application/vnd.ms-excel"
    name="hs_codes"
    :show-upload-list="false"
    :action="url"
    :headers="headers"
    :beforeUpload="validateFormat"
    @change="handleUpload"
  >
    <sdButton
      class="btn-signin mr-10"
      type="light"
      html-type="button"
      :loading="isLoading"
      @click="$event.preventDefault()"
    >
      {{$t('views.priceCategories.bulkUpload')}}
    </sdButton>
  </a-upload>
</template>

<script>
import {defineComponent, ref} from 'vue';
import {useStore} from 'vuex';
import {useI18n} from 'vue-i18n';
import {theme} from '@/config/theme/themeVariables';
import {displayServerErrorsInToaster, displaySuccessToaster} from '@/helpers/toasters';
import _ from 'lodash';

export default defineComponent({
  name: 'QuoteUploadButton',
  emits: ['success'],
  setup(props, context) {
    const {
      state, getters
    } = useStore();
    const {t} = useI18n();

    const fileList = ref([]);
    const headers = ref({
      'access-token': state.session.accessToken,
      'client': state.session.client,
      'uid': state.session.uid
    });
    const url = getters.baseURL + '/price_category_items/hs_codes';
    const isLoading = ref(false);

    const validateFormat = (file) => {
      if (!/xls[mx]?/gm.test(file.name)) {
        displayServerErrorsInToaster([t('messages.error.fileFormatExcel')]);
        return false;
      }
    };

    const handleUpload = ({file, event}) => {
      event?.preventDefault();

      if (file.status === 'uploading') {
        isLoading.value = true;
      } else if (file.status === 'error') {
        isLoading.value = false;
        displayServerErrorsInToaster([t('messages.error.fileFormatExcel')]);
      } else if (file.status === 'done') {
        context.emit('success', file.response[_.snakeCase('hsCodes')]);
        displaySuccessToaster(t('views.priceCategories.hsCodesUploaded'));
      }
      isLoading.value = false;
    };

    return {
      theme,
      fileList,
      url,
      headers,
      isLoading,
      validateFormat,
      handleUpload,
      bodyStyle: {'padding-top': '50px'}
    };
  }
});
</script>
